import { PageLoader } from "@forwardflow/ui";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Settings = lazy(() => import("../../components/Settings"));
const UserManagement = lazy(
  () => import("../../components/Settings/UserManagement")
);
const ViewUserAccount = lazy(
  () => import("../../components/Settings/ViewUserAccount")
);
const BillingAndSubscription = lazy(
  () => import("../../components/Settings/Billing")
);

const SettingsRoutes = () => {
  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="" element={<Settings />}>
            <Route path="user-management" element={<UserManagement />}></Route>
            <Route path="user-management/:id" element={<ViewUserAccount />} />
            <Route path="billing" element={<BillingAndSubscription />}></Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default SettingsRoutes;
