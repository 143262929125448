import axios from "axios";
import errorResponseHandler from "./errorResponseHandler";
import { ENV } from "@forwardflow/helpers/configs";
import LocalStorage from "../LocalStorage";

const API = axios.create({
  baseURL: ENV.PUBLIC_FF_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

API.interceptors.request.use((config) => {
  const accessToken = LocalStorage?.get("access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

API.interceptors.response.use(
  (response) => {
    const responseObject: any = {
      success: true,
      statusCode: response?.data?.statusCode,
      message: response?.data?.message,
      data: response?.data?.data,
      meta: response?.data?.meta,
    };
    return responseObject;
  },
  (err) => {
    errorResponseHandler(err);
    return Promise.reject(err);
  }
);

export { API };
