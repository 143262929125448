import { create } from "zustand";

interface ModalState {
   isCalendarViewModalOpen: boolean;
   openCalendarViewModal: () => void;
   closeCalendarViewModal: () => void;
}

export const useCalendarEventViewModalStore = create<ModalState>((set) => ({
   isCalendarViewModalOpen: false,
   openCalendarViewModal: () => set({ isCalendarViewModalOpen: true }),
   closeCalendarViewModal: () => set({ isCalendarViewModalOpen: false }),
}));
