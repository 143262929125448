import { create } from "zustand";

interface ModalState {
  isVerifyEINModalOpen: boolean;
  openVerifyEINModal: () => void;
  closeVerifyEINModal: () => void;
}

export const useVerifyEINModalStore = create<ModalState>((set) => ({
  isVerifyEINModalOpen: false,
  openVerifyEINModal: () => set({ isVerifyEINModalOpen: true }),
  closeVerifyEINModal: () => set({ isVerifyEINModalOpen: false }),
}));
