import "./App.scss";
import AppAnalytics from "./hooks/customHooks/appAnalytics";
import Routing from "./router/Router";

function App() {
  return (
    <>
      <main className="min-h-screen">
        <AppAnalytics />
        <Routing></Routing>
      </main>
    </>
  );
}

export default App;
