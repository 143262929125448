import { apiGet, apiPost } from "@forwardflow/services/Axios/configs";
import { IUser, IUserInvite, ResponseSuccess } from "@forwardflow/types";

export class UserService {
  // Get user data
  static async getUserData() {
    const data = await apiGet<ResponseSuccess<IUser>>({
      apiPath: `/users/me`,
    });

    return data.data;
  }
  // Send user invitation mail
  static userSendInvitation = async (id: string[]) => {
    const data = await apiPost<ResponseSuccess<IUserInvite>>({
      apiPath: `/users/send-invitation-email`,
      data: {
        userIds: id,
        redirectUrl: "/",
      },
    });
    return data.data;
  };
}
