import axios, { AxiosRequestConfig } from "axios";
import { API } from ".";

interface HTTPRequestCommon {
  apiPath: string;
  data?: any;
  config?: AxiosRequestConfig;
  withCredentials?: boolean;
  external?: boolean;
}

async function apiRequest<T>(
  method: "get" | "post" | "put" | "patch" | "delete",
  {
    apiPath,
    data,
    config = {},
    withCredentials = false,
    external = false,
  }: HTTPRequestCommon
): Promise<T> {
  const axiosToUse = external ? axios : API;
  const fullUrl = apiPath;
  const newConfig: AxiosRequestConfig = {
    ...config,
    withCredentials,
  };

  // eslint-disable-next-line no-useless-catch
  try {
    let response;
    if (method === "get") {
      response = await axiosToUse.get(fullUrl, newConfig);
    } else if (method === "post") {
      response = await axiosToUse.post(fullUrl, data, newConfig);
    } else if (method === "put") {
      response = await axiosToUse.put(fullUrl, data, newConfig);
    } else if (method === "patch") {
      response = await axiosToUse.patch(fullUrl, data, newConfig);
    } else if (method === "delete") {
      response = await axiosToUse.delete(fullUrl, newConfig);
    }
    if (response) {
      return response as T;
    } else {
      throw new Error("Response is undefined");
    }
  } catch (error) {
    throw error;
  }
}

const apiGet = <T>(params: HTTPRequestCommon): Promise<T> =>
  apiRequest<T>("get", params);

const apiPost = <T>(params: HTTPRequestCommon): Promise<T> =>
  apiRequest<T>("post", params);

const apiPut = <T>(params: HTTPRequestCommon): Promise<T> =>
  apiRequest<T>("put", params);

const apiPatch = <T>(params: HTTPRequestCommon): Promise<T> =>
  apiRequest<T>("patch", params);

const apiDelete = (params: HTTPRequestCommon): Promise<void> =>
  apiRequest<void>("delete", params);

export { apiDelete, apiGet, apiPatch, apiPost, apiPut };
