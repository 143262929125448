import { create } from "zustand";

interface FubApiKeyState {
  apiKey: string | "";
  setApiKey: (key: string) => void;
  clearApiKey: () => void;
}

export const useFubApiKeyStore = create<FubApiKeyState>((set) => ({
  apiKey: "",
  setApiKey: (key) => set({ apiKey: key }),
  clearApiKey: () => set({ apiKey: "" }),
}));
