import { notification } from "antd";
import validationNotification from "./validationNotification";
import axios from "axios";

export default function errorResponseHandler(err: unknown) {
  if (axios.isAxiosError(err) && err?.response) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    switch (err.response.status) {
      case 400:
        // HTTP_BAD_REQUEST = 400
        notification.warning({
          message: "Check your entries and try again",
          description:
            err?.response?.data?.errors?.[0] ?? err?.response?.data?.message,
          placement: "bottomRight",
        });
        break;
      case 401:
        // HTTP_UNAUTHORIZED = 401
        // log user our, clear Storage storage & auth context etc.
        notification.warning({
          message: "Unauthorized",
          description:
            err?.response?.data?.errors?.[0] ?? err?.response?.data?.message,
          placement: "bottomRight",
        });
        break;
      case 402:
        // HTTP_PAYMENT_REQUIRED = 402
        break;
      case 403:
        // HTTP_FORBIDDEN = 403
        if (!(err?.response?.config?.url ?? "").includes("/accounts")) {
          notification.warning({
            message: "You're not authorized to complete that action",
            description:
              err?.response?.data?.errors?.[0] ??
              err?.response?.data?.message ??
              "Please refresh your browser or try logging out & back in again",
            placement: "bottomRight",
            key: "403errorNotification",
          });
        }
        break;
      case 404:
        // HTTP_NOT_FOUND = 404
        notification.warning({
          message: "That resource wasn't found.",
          description:
            "Please refresh your browser or try logging out & back in again",
          placement: "bottomRight",
          key: "404errorNotification",
        });
        break;
      case 422:
        // handle validator unprocessable
        validationNotification(err?.response?.data?.errors);
        break;
      case 423:
        // handle password confirmation required

        notification.warning({
          message: "Please confirm your password to continue",
          description: "Access of sensitive data is restricted",
          placement: "bottomRight",
        });
        break;
      default:
      // handle every other code outside 2xx

      // notification.error({
      // 	message: 'Something went wrong. Please try again',
      // 	description:
      // 		err?.response?.data?.errors?.[0] ?? err?.response?.data?.message,
      // 	placement: 'bottomRight'
      // })
    }
  }
}
