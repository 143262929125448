import { QUERY_KEYS } from "@forwardflow/helpers/utils/constants";
import { UserService } from "@forwardflow/services/UserService";
import useSWR from "swr";

export default function useAuthUser() {
  const response = useSWR([QUERY_KEYS.AUTH_USER], UserService.getUserData);
  return {
    ...response,
  };
}
