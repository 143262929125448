import { create } from "zustand";

interface ModalState {
  isMapStageModalOpen: boolean;
  openMapStageModal: () => void;
  closeMapStageModal: () => void;
}

export const useMapStageStore = create<ModalState>((set) => ({
  isMapStageModalOpen: false,
  openMapStageModal: () => set({ isMapStageModalOpen: true }),
  closeMapStageModal: () => set({ isMapStageModalOpen: false }),
}));
