import { create } from "zustand";

interface ModalState {
   isRemoveQualifyingModalOpen: boolean;
   openRemoveQualifyingModal: () => void;
   closeRemoveQualifyingModal: () => void;
}

export const useQualifyingQualifyingModalStore = create<ModalState>((set) => ({
   isRemoveQualifyingModalOpen: false,
   openRemoveQualifyingModal: () => set({ isRemoveQualifyingModalOpen: true }),
   closeRemoveQualifyingModal: () =>
      set({ isRemoveQualifyingModalOpen: false }),
}));
