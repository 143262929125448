import { create } from "zustand";

interface ModalState {
  isRemovePhoneNumberModalOpen: boolean;
  openRemovePhoneNumberModal: () => void;
  closeRemovePhoneNumberModal: () => void;
}

export const useRemovePhoneNumberModalStore = create<ModalState>((set) => ({
  isRemovePhoneNumberModalOpen: false,
  openRemovePhoneNumberModal: () => set({ isRemovePhoneNumberModalOpen: true }),
  closeRemovePhoneNumberModal: () =>
    set({ isRemovePhoneNumberModalOpen: false }),
}));
