import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { PageLoader } from "@forwardflow/ui";
import SettingsLayout from "@forwardflow/layouts/SettingsLayout";

const Signup = lazy(() => import("../pages/SignUp"));
const Signin = lazy(() => import("../pages/SignIn"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const SetNewPassword = lazy(() => import("../pages/SetNewPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const OnboardingLayout = lazy(() => import("../layouts/OnboardingLayout"));
const VerifyEmail = lazy(() => import("../pages/Onboarding/VerifyEmail"));
const CampaignLayout = lazy(() => import("../layouts/CampaignLayout"));
const PhoneNumberLayout = lazy(() => import("../layouts/PhoneNumberLayout"));
const Calendar = lazy(() => import("../pages/Calendar"));

const Routing = () => {
   return (
      <>
         <BrowserRouter>
            <Suspense fallback={<PageLoader />}>
               <Routes>
                  <Route path="/" element={<Signin />}></Route>
                  <Route path="/verify-email" element={<VerifyEmail />}></Route>
                  <Route path="/signup" element={<Signup />}></Route>
                  <Route path="/signin" element={<Signin />}></Route>
                  <Route
                     path="/forgot-password"
                     element={<ForgotPassword />}
                  ></Route>
                  <Route
                     path="/set-new-password"
                     element={<SetNewPassword />}
                  ></Route>
                  <Route element={<ProtectRoutes />}>
                     <Route
                        path="/onboarding/*"
                        element={<OnboardingLayout />}
                     />
                     <Route path="/dashboard" element={<Dashboard />}></Route>
                     <Route
                        path="/campaign/*"
                        element={<CampaignLayout />}
                     ></Route>
                     {/* <Route
                                path="/conversations"
                                element={<Conversations />}
                            ></Route> */}
                     <Route
                        path="/phone-numbers/*"
                        element={<PhoneNumberLayout />}
                     ></Route>
                     <Route
                        path="/settings/*"
                        element={<SettingsLayout />}
                     ></Route>
                     <Route path="/calendar/*" element={<Calendar />}></Route>
                  </Route>
               </Routes>
            </Suspense>
         </BrowserRouter>
      </>
   );
};

export default Routing;
