export default class LocalStorage {
  public static put(key: string, value: any) {
    if (typeof window === "undefined") return;
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static get(key: string) {
    if (typeof window === "undefined") return null;
    
    return JSON.parse(localStorage.getItem(key) ?? 'null')
  }

  public static clear() {
    if (typeof window === "undefined") return;

    localStorage.clear();
  }

  public static delete(key: string) {
    if (typeof window === "undefined") return;

    localStorage.removeItem(key);
  }
}
