import { notification } from 'antd'

export default function validationNotification(errors: any) {
	Object.entries(errors).forEach((item) => {
		if (Array.isArray(item[1])) {
			notification.warning({
				message: 'Check your entries and try again',
				description: item[1][0],
				placement: 'bottomRight'
			})
		}
	})
}
