import { create } from "zustand";

interface ModalState {
  isReplacePhoneNumberModalOpen: boolean;
  openReplacePhoneNumberModalModal: () => void;
  closeReplacePhoneNumberModal: () => void;
}

export const useReplacePhoneNumberModalStore = create<ModalState>((set) => ({
  isReplacePhoneNumberModalOpen: false,
  openReplacePhoneNumberModalModal: () =>
    set({ isReplacePhoneNumberModalOpen: true }),
  closeReplacePhoneNumberModal: () =>
    set({ isReplacePhoneNumberModalOpen: false }),
}));
