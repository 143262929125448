import { create } from "zustand";

interface ModalState {
  isInviteAgentModalOpen: boolean;
  openInviteAgentModal: () => void;
  closeInviteAgentModal: () => void;
}

export const useInviteAgentModalStore = create<ModalState>((set) => ({
  isInviteAgentModalOpen: false,
  openInviteAgentModal: () => set({ isInviteAgentModalOpen: true }),
  closeInviteAgentModal: () => set({ isInviteAgentModalOpen: false }),
}));
