import SettingsRoutes from "@forwardflow/router/SettingsRoutes";

const SettingsLayout = () => {
  return (
    <>
      <SettingsRoutes />
    </>
  );
};

export default SettingsLayout;
