import { create } from "zustand";

interface ModalState {
  isA2PRegistrationModalOpen: boolean;
  openA2PRegistrationModal: () => void;
  closeA2PRegistrationModal: () => void;
}

export const useA2PRegistrationModalStore = create<ModalState>((set) => ({
  isA2PRegistrationModalOpen: false,
  openA2PRegistrationModal: () => set({ isA2PRegistrationModalOpen: true }),
  closeA2PRegistrationModal: () => set({ isA2PRegistrationModalOpen: false }),
}));
