import { create } from "zustand";

interface ModalState {
  isInviteAdminModalOpen: boolean;
  openInviteAdminModal: () => void;
  closeInviteAdminModal: () => void;
}

export const useInviteAdminModalStore = create<ModalState>((set) => ({
  isInviteAdminModalOpen: false,
  openInviteAdminModal: () => set({ isInviteAdminModalOpen: true }),
  closeInviteAdminModal: () => set({ isInviteAdminModalOpen: false }),
}));
