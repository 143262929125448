import AuthProvider from "@forwardflow/context/AuthContext";
import LocalStorage from "@forwardflow/services/LocalStorage";
import { Outlet, Navigate } from "react-router-dom";

const ProtectRoutes = () => {
  const user = LocalStorage.get("access_token") !== null ? true : false;

  return user ? (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectRoutes;
